import React, { Component } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import {
  Container,
  Header as SUIHeader,
  Grid,
  Button as SUIButton,
  Form,
} from "semantic-ui-react"
import ReactPlayer from "react-player"
import axios from "axios"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.min.css"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Hero from "../../components/hero"
import TextContainer from "../../components/text-container"

const GridRow = styled(Grid.Row)`
  @media only screen and (min-width: 1200px) {
    padding: 6em 0em 0em 0em !important;
  }
`

const GridColumn = styled(Grid.Column)`
  padding: 0em 5em !important;
  text-align: justify !important;
`

const Button = styled(SUIButton)`
  background: #004165 !important;
  color: white !important;
`

class IndexPage extends Component {
  state = {
    address: "",
    name: "",
    email: "",
    phone: "",
    gdpr: false,
    contact: false,
    message: "",
    gotcha: null,
  }
  handleChange = event => {
    const name = event.target.name
    const value = event.target.value

    this.setState({
      [name]: value,
    })
  }

  handleCheckbox = event => {
    const name = event.target.name
    const checked = event.target.checked

    this.setState({
      [name]: checked,
    })
  }

  submitForm = e => {
    e.preventDefault()
    axios
      .post("https://formspree.io/mwrnzqex", {
        address: this.state.address,
        name: this.state.name,
        _replyto: this.state.email,
        message: this.state.message,
        phone: this.state.phone,
        gdpr: this.state.gdpr,
        contact: this.state.contact,
        _subject: "Form Submission - Host Enquiry",
        _gotcha: this.state.gotcha,
        _cc: "info@hostfamilyenglish.com",
      })
      .then(res => {
        toast.success("Form Successfully Sent")
        this.setState({
          agency: "",
          address: "",
          name: "",
          email: "",
          message: "",
          phone: "",
          gdpr: false,
          contact: false,
        })
      })
      .catch(err => {
        console.log(err)
        toast.error("An error occurred when sending the form")
      })
  }

  render() {
    return (
      <Layout>
        <SEO
          title="Contact Us - Host Enquiries"
          keywords={[`host family english`, `hfe`]}
        />
        <Grid
          style={{
            marginLeft: "0em",
            marginRight: "0em",
          }}
        >
          <GridRow columns="2" centered>
            <Grid.Column largeScreen="6" computer="8" tablet="8" mobile="16">
              <h2 style={{ textAlign: "center" }}>Host Enquiries</h2>
              <Form onSubmit={e => this.submitForm(e)}>
                <Form.Input
                  placeholder="Contact Name"
                  name="name"
                  id="name"
                  value={this.state.name}
                  onChange={this.handleChange}
                  required
                />
                <Form.Input
                  placeholder="Home Address"
                  name="address"
                  id="address"
                  value={this.state.address}
                  onChange={this.handleChange}
                />
                <Form.Input
                  placeholder="Email"
                  type="email"
                  name="email"
                  id="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
                <Form.Input
                  placeholder="Phone Number"
                  name="phone"
                  id="phone"
                  value={this.state.phone}
                  onChange={this.handleChange}
                />
                <Form.TextArea
                  placeholder="Comments"
                  name="message"
                  id="message"
                  value={this.state.message}
                  onChange={this.handleChange}
                  required
                />
                <Form.Checkbox
                  name="gdpr"
                  id="gdpr"
                  defaultChecked={this.state.gdpr}
                  onChange={this.handleCheckbox}
                  label="I agree with Host Family English's privacy policy which can be found at the bottom this page.*"
                  required
                />
                <Form.Checkbox
                  name="contact"
                  id="contact"
                  defaultChecked={this.state.contact}
                  onChange={this.handleCheckbox}
                  ref="contact"
                  label="Host Family English can contact me by email or phone with related information.*"
                  required
                />
                <Form.Group style={{ justifyContent: "center" }}>
                  <Button type="submit">Submit</Button>
                </Form.Group>
              </Form>
            </Grid.Column>
          </GridRow>
        </Grid>
        <ToastContainer />
      </Layout>
    )
  }
}

export default IndexPage
